
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import useTranslation from 'next-translate/useTranslation';
import ErrorBlock from '@/components/molecules/ErrorBlock';

const Index = () => {
  const { t } = useTranslation('error');

  return (
    <ErrorBlock
      title={`404 ${t('Error')}`}
      subTitle={t('PageDoesNotExist')}
      ctaLabel={t('BackToHome')}
      ctaLink="/"
    />
  );
};

export default Index;


    export async function getStaticProps(ctx) {
        
        
        return {
          
          
          props: {
            
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/404',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              
            }))
          }
        }
    }
  